/* eslint-disable @typescript-eslint/no-explicit-any */
export const registrations = [
    {
        name: 'Header',
        import: () => import('@stories/Widgets/Global/Header/Header'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'Footer',
        import: () => import('@stories/Widgets/Global/Footer/Footer'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'GlobalStyles',
        import: () => import('@stories/Widgets/Global/GlobalStyles/GlobalStyles'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'HeroBanner',
        import: () => import('@stories/Widgets/HeroBanner/HeroBanner'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'LatestArticlesCarousel',
        import: () => import('@stories/Widgets/Carousels/LatestArticlesCarousel/LatestArticlesCarousel'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'CuratedCarousel',
        import: () => import('@stories/Widgets/Carousels/CuratedCarousel/CuratedCarousel'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Statistics',
        import: () => import('@stories/Widgets/Statistics/Statistics'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'RichText',
        import: () => import('@stories/Widgets/RichText/RichText'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'OurPeopleCarousel',
        import: () => import('@stories/Widgets/Carousels/OurPeopleCarousel/OurPeopleCarousel'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Listing',
        import: () => import('@stories/Widgets/Listing/Listing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ContactDetails',
        import: () => import('@stories/Widgets/ContactDetails/ContactDetails'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'AccordionList',
        import: () => import('@stories/Widgets/AccordionList/AccordionList'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'SplitContent',
        import: () => import('@stories/Widgets/SplitContent/SplitContent'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'StockDetails',
        import: () => import('@stories/Widgets/StockDetails/StockDetails'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'HistoryCarousel',
        import: () => import('@stories/Widgets/Carousels/HistoryCarousel/HistoryCarousel'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'SplitArticleListing',
        import: () => import('@stories/Widgets/SplitArticleListing/SplitArticleListing'),
        config: {
            hydrate: 'in-view',
        },
    },
];
